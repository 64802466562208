import { handleErrorWithSentry } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: import.meta.env.SENTRY_DSN,
  environment: import.meta.env.SENTRY_ENVIRONMENT ?? import.meta.env.MODE ?? import.meta.env.NODE_ENV ?? 'development',
  release: import.meta.env.SENTRY_RELEASE ?? null,
  tracesSampleRate:  .5,
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
